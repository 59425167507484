// Generated by Framer (11696e7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ViEhdoQlD", "MxhQ_SShG"];

const serializationHash = "framer-3PLn5"

const variantClassNames = {MxhQ_SShG: "framer-v-sgx2z6", ViEhdoQlD: "framer-v-14br5d7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "MxhQ_SShG", Menu: "ViEhdoQlD"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ViEhdoQlD", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ViEhdoQlD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16y2uw0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-14br5d7", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"ViEhdoQlD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16y2uw0} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({MxhQ_SShG: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-17rl7jd"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"q2HrqVKwP"} style={{backgroundColor: "var(--token-6555779b-6d9a-4514-84e6-845325a32d37, rgb(0, 0, 0))", rotate: 0}} variants={{MxhQ_SShG: {rotate: -45}}}/><motion.div className={"framer-c8jx7r"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"Xj5xbU4eZ"} style={{backgroundColor: "var(--token-6555779b-6d9a-4514-84e6-845325a32d37, rgb(0, 0, 0))", rotate: 0}} variants={{MxhQ_SShG: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3PLn5.framer-1de22zr, .framer-3PLn5 .framer-1de22zr { display: block; }", ".framer-3PLn5.framer-14br5d7 { cursor: pointer; height: 48px; overflow: hidden; position: relative; width: 48px; }", ".framer-3PLn5 .framer-17rl7jd { flex: none; height: 3px; left: calc(50.00000000000002% - 32px / 2); overflow: hidden; position: absolute; top: calc(60.416666666666686% - 3px / 2); width: 32px; }", ".framer-3PLn5 .framer-c8jx7r { flex: none; height: 3px; left: calc(50.00000000000002% - 32px / 2); overflow: hidden; position: absolute; top: calc(39.58333333333336% - 3px / 2); width: 32px; }", ".framer-3PLn5.framer-v-sgx2z6.framer-14br5d7 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); }", ".framer-3PLn5.framer-v-sgx2z6 .framer-17rl7jd, .framer-3PLn5.framer-v-sgx2z6 .framer-c8jx7r { left: calc(50.00000000000002% - 28px / 2); top: calc(50.00000000000002% - 3px / 2); width: 28px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MxhQ_SShG":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameruj5neULHa: React.ComponentType<Props> = withCSS(Component, css, "framer-3PLn5") as typeof Component;
export default Frameruj5neULHa;

Frameruj5neULHa.displayName = "Component / Menu Icon";

Frameruj5neULHa.defaultProps = {height: 48, width: 48};

addPropertyControls(Frameruj5neULHa, {variant: {options: ["ViEhdoQlD", "MxhQ_SShG"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Frameruj5neULHa, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})